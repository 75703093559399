import React from 'react';

import useCurrentPage from '../Shared/Hooks/useCurrentPage';
import { styled } from '@glitz/react';
import { theme } from '../Theme';
import { media } from '@glitz/core';
import MyPlanogramsSeasonalComponent from './MyPlanogramsSeasonalComponent';
import MyPlanogramsSeasonalPageModel from './Models/MyPlanogramsSeasonalModel.interface';
import ArrowLeftIconPrimary from '../Shared/Icons/ArrowLeftIconPrimary';
import KexLink from '../Shared/KexLink/KexLink';
import { useAppSettingsData } from '../Shared/AppSettingsProvider/AppSettingsProvider';

function MyPlanogramsSeasonalPage() {
  const {
    translations: { 'myPlanogramsPage/backToMyPage': backLinkLabel },
    staticPages: { myPage },
  } = useAppSettingsData();

  const { pageHeading, pageIntroText } = useCurrentPage<
    MyPlanogramsSeasonalPageModel
  >();
  return (
    <main>
      <BackLinkWrapper>
        <ArrowLeftIcon />
        <BackLink href={myPage}>{backLinkLabel}</BackLink>
      </BackLinkWrapper>
      <TopContent>
        <Heading>{pageHeading}</Heading>
        <Intro>{pageIntroText}</Intro>
      </TopContent>
      <MyPlanogramsSeasonalComponent showAllSeasonalPlanograms={true} />
    </main>
  );
}

const BackLinkWrapper = styled.div({
  position: 'absolute',
  top: theme.desktopHeaderHeight,
  left: 0,
  right: 0,
  margin: { x: 'auto' },
  maxWidth: theme.screenMaxWidth,
  width: '100%',
  paddingTop: '18px',
  alignItems: 'center',
  display: 'flex',
  paddingLeft: '32px',
  lineHeight: 1.33,
  letterSpacing: 0.45,
  ...media(theme.mediaQuery.mediaMaxLarge, {
    paddingTop: '0px',
  }),
  ...media(theme.mediaQuery.mediaMaxMedium, {
    paddingLeft: '20px',
  }),
});

const ArrowLeftIcon = styled(ArrowLeftIconPrimary, {
  fill: theme.linkColor,
  marginRight: theme.spacing(4),
});

const BackLink = styled(KexLink, {
  fontSize: theme.gamma,
  color: theme.linkColor,
  cursor: 'pointer',
  textDecoration: 'underline',
});

const TopContent = styled.div({
  width: '100%',
  color: theme.black,
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.spacing(15),
  maxWidth: theme.blockMaxWidthMobile,
  padding: {
    x: '7.5px',
  },
  paddingTop: theme.spacing(10),
  ...media(theme.mediaQuery.mediaMinMedium, {
    margin: { x: 'auto' },
  }),
  ...media(theme.mediaQuery.mediaMinLarge, {
    margin: { y: theme.spacing(14) },
    padding: { x: theme.medium, y: theme.none },
    maxWidth: '900px',
  }),
});

const Heading = styled.h1({
  font: { size: theme.psi, weight: theme.fontWeight.bold },
  marginBottom: theme.spacing(5),
  ...media(theme.mediaQuery.mediaMinLarge, {
    lineHeight: '40px',
    fontSize: '32px',
    marginTop: theme.spacing(5),
  }),
});

const Intro = styled.p({
  lineHeight: '28.8px',
});

export default MyPlanogramsSeasonalPage;
