import React from 'react';
import { styled } from '../../Theme';
import { StyledProps } from '@glitz/react';

function PlanogramIcon({ compose }: StyledProps) {
  return (
    <styled.Svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      css={compose()}
    >
      <g clipPath="url(#clip0)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.75 1.5C1.61193 1.5 1.5 1.61193 1.5 1.75V22.25C1.5 22.3881 1.61193 22.5 1.75 22.5H22.25C22.3881 22.5 22.5 22.3881 22.5 22.25V1.75C22.5 1.61193 22.3881 1.5 22.25 1.5H1.75ZM0 1.75C0 0.783502 0.783502 0 1.75 0H22.25C23.2165 0 24 0.783502 24 1.75V22.25C24 23.2165 23.2165 24 22.25 24H1.75C0.783502 24 0 23.2165 0 22.25V1.75Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.511719 7.5C0.511719 7.08579 0.847505 6.75 1.26172 6.75H23.2507C23.6649 6.75 24.0007 7.08579 24.0007 7.5C24.0007 7.91421 23.6649 8.25 23.2507 8.25H1.26172C0.847505 8.25 0.511719 7.91421 0.511719 7.5Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.511719 16.5C0.511719 16.0858 0.847505 15.75 1.26172 15.75H23.2507C23.6649 15.75 24.0007 16.0858 24.0007 16.5C24.0007 16.9142 23.6649 17.25 23.2507 17.25H1.26172C0.847505 17.25 0.511719 16.9142 0.511719 16.5Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.75 6.75C16.1642 6.75 16.5 7.08579 16.5 7.5V16.5C16.5 16.9142 16.1642 17.25 15.75 17.25C15.3358 17.25 15 16.9142 15 16.5V7.5C15 7.08579 15.3358 6.75 15.75 6.75Z"
          fill="black"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.25 6.75C8.66421 6.75 9 7.08579 9 7.5V16.5C9 16.9142 8.66421 17.25 8.25 17.25C7.83579 17.25 7.5 16.9142 7.5 16.5V7.5C7.5 7.08579 7.83579 6.75 8.25 6.75Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </styled.Svg>
  );
}

export default styled(PlanogramIcon);
