import Fetcher from '../Shared/Common/Fetcher';
import SimpleProduct from '../PlanogramPage/Models/SimpleProduct.interface';

let abortController: AbortController = new AbortController();

export async function GetSeasonalPlanograms(
  channelId: string,
  requestToken: string,
  url: string,
  setIsLoading?: (value: boolean) => void
) {
  setIsLoading && setIsLoading(true);
  abortController.abort();
  abortController = new AbortController();
  const signal = abortController.signal;

  const litiumContext = JSON.stringify({
    channelSystemId: channelId,
  });
  return Fetcher<any, SimpleProduct[]>(
    url,
    signal,
    (data, resolve) => {
      resolve(data);
      setIsLoading && setIsLoading(false);
    },
    litiumContext,
    requestToken
  );
}
