import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import { theme } from '../../Theme';

function ArrowLeftIconPrimary({ compose }: StyledProps) {
  return (
    <styled.Svg
      width="14"
      height="14"
      css={compose()}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path
        d="M7.841 6.134c.18.168.208.437.08.636l-.055.071L1.65 13.5H15.5c.276 0 .5.224.5.5s-.224.5-.5.5H1.652l6.214 6.659c.167.18.177.45.035.64l-.06.067c-.18.167-.45.177-.64.035l-.067-.06-7-7.5c-.156-.168-.176-.417-.058-.606l.058-.076 7-7.5c.189-.202.505-.213.707-.025z"
        transform="translate(-512 -118) translate(512 112)"
      />
    </styled.Svg>
  );
}

export default styled(ArrowLeftIconPrimary);
